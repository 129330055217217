import * as React from "react";
import Layout from "../../components/Layout";
import { Seo } from "../../components/seo";
import Counter from "../../components/Counter";
import FAQAccord from "../../components/GFlush";
import GeneralTab from "../../components/HTabs";
import Service from "../../components/Services";
import Document from "../../components/Document";
import About from "../../components/About";
// import Hero from "../../components/Hero";
import Cta from "../../components/Cta";
import Package from "../../components/GSTTemp/Package";

export default function AnnualCompany() {
  // Banner slider
  // const AnnualCompSlider = [
  //   {
  //     header_color: false,
  //     content_color: false,
  //     marginTop: 30,
  //     background: `linear-gradient(139.4deg, rgb(198, 243, 242) 28.54%, rgb(181, 201, 253) 104.43%)`,
  //     header: `Annual Compliance of a Pvt. Ltd. Company ROC filing/ ITR`,
  //     content: `Annual Compliance of a Pvt. Ltd. Company ROC filing/ ITR Registration Online with TODAYFILINGS Experts`,
  //     image: "/imgs/business/sliders/one-person.png",
  //     alt_tag: "Best Online Annual Compliance of a Pvt. Ltd. Company ROC filing/ ITR Registration Service Near Bangalore",
  //   },
  //   {
  //     header_color: false,
  //     content_color: false,
  //     marginTop: 95,
  //     background: `linear-gradient(139.4deg, rgb(207, 249, 216) 28.54%, rgb(243, 238, 160) 104.43%)`,
  //     header: `Online Annual Compliance of a Pvt. Ltd. Company ROC filing/ ITR Registration in Hosur`,
  //     content: `Best Annual Compliance of a Pvt. Ltd. Company ROC filing/ ITR Registration Service in Hosur`,
  //     image: "/imgs/business/sliders/onecompslider.png",
  //     alt_tag: "Best Online Annual Compliance of a Pvt. Ltd. Company ROC filing/ ITR Registration in Hosur",
  //   },
  //   {

  //     background: `linear-gradient(139.4deg, rgb(252, 237, 216) 28.54%, rgb(245, 197, 198) 104.43%)`,
  //     marginTop: 95,
  //     header: `Annual Compliance of a Pvt. Ltd. Company ROC filing/ ITR Registration Service`,
  //     content: `Best Annual Compliance of a Pvt. Ltd. Company ROC filing/ ITR Registration Service in Hosur`,
  //     image: "/imgs/business/sliders/one-slider.png",
  //     alt_tag: "Annual Compliance of a Pvt. Ltd. Company ROC filing/ ITR Registration in Hosur",
  //   },
  // ];

  /* Package Data */
  const PackageCompData = {
    header: `Annual Compliance Of Pvt Ltd Company`,
    buyBtnLink: `#pricing-buy`,
    price: `7999`,
    content: [
      {
        points: `PAN & TAN Registration `,
        icon: "/imgs/icons/gst.png",
        alt_tag: "PAN & TAN Registration",
        background: "",
        plus: "+",
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: "/imgs/icons/itr_filing.png",
        alt_tag: "TODAYFILINGS ITR E-filing service",
        background: "bg-f78acb",
        plus: "+",
        plus_dnone: "d-none d-md-block",
      },
      {
        points: `MSME Registration`,
        icon: "/imgs/icons/enterprises.png",
        alt_tag: "MSME Registration",
        background: "bg-eb6b3d",
        plus: "+",
      },
      {
        points: `Tax Consultancy Service `,
        icon: "/imgs/icons/consultant.png",
        alt_tag: "Tax Consultancy Service",
        background: "bg-c679e3",
      },
    ],
  };
  /* About Data Without Form*/
  const AnnualCompAboutData = {
    title: `Annual Compliance Registration of a Pvt. Ltd. Company`,
    sub_title: `Annual Compliance of a Pvt. Ltd. Company Registration in just 7 Days`,
    paragraph: `One of the highly recommended small enterprises in India,
      particularly for startups, is a Private Limited Company,
      which is run privately. The Companies Act of 2013 governs
      the registration of private limited companies in India. A
      private corporation must have a minimum of 2 shareholders
      and a maximum of 200 members, per the Companies Act of 2013.
      The personal assets of members or shareholders of a private
      limited company are not susceptible to sale in the event of
      a financial danger, thus they should have minimal
      responsibility.`,
    header: `Annual Compliances for Private Limited Company`,
    points: [
      `Board Meeting`,
      `Disclosure by director`,
      `Annual General Meeting`,
      `Commencement of Business`,
    ],
  };
  /* Service Data */
  const AnnualCompData = {
    header: `Benefits of Annual Compliance`,
    content: `Confusion in business ethics can be avoided with professional
    advice and assistance from TODAYFILINGS for Private Limited Company
    Compliance. Keeping us on your team is equivalent to having a
    separate professional team in charge of a Pvt. Ltd. company's
    annual compliance, ROC filing, ITR filing, and regular MCA
    filings to maintain the company's active status.`,
    image: '../imgs/gifs/compliances.gif',
    alt_img: 'TODAYFILINGS-annual-compliance-of-a-private-limited-company',
    points: [
      `Increase The Company’s Credibility`,
      `Attract Investors`,
      `Maintain Active Status`,
      `Exemption to donors`,
    ],
  };

  /* FaQ Data */

  var AnnualCompFAQ = [
    {
      header: 'What are the Documents Required for Annual Filing of Company?',
      body: [
        {
          content: `Certificate of Incorporation, PAN Card, MoA, and AoA of 
          Private Limited Company are all included in the incorporation documents.`,
          icon: true,
        },
        {
          content: `Financial statements that have been audited Independent auditors 
          should audit financial statements.`,
          icon: true,
        },
        {
          content: `It is also necessary to have a board report and an independent 
          auditor's report.`,
          icon: true,
        },
        {
          content: `Digital Signature Certificate (DSC) or DSC of Director: One of the
          directors must produce a valid and current DSC or DSC.`,
          icon: true,
        },
      ],
    },
    // 2
    {
      header: 'What application is made for the position of Auditor?',
      body: [
        {
          content: ` For the appointment or removal of the Statutory Auditor, Form ADT-1
          must be submitted.`,
          icon: false,
        },
      ],
    },
    {
      header: `Does the company's yearly compliance requirement apply to the
      auditor's designation?`,
      body: [
        {
          content: `A firm has the option of choosing to name a statutory auditor for
          five consecutive years or through the conclusion of the following
          AGM. A designation of the statutory auditor, however, is not
          considered to be a "annual compliance."`,
          icon: false,
        },
      ],
    },
    // 3
    {
      header:
        'Is the annual filing of an audited financial statement required?',
      body: [
        {
          content: `Yes, after a company's establishment, audited financial statements
          are a requirement. Only the audited financial and administrative
          records must be filed by the company. Additionally, the failure to
          audit the financial statements is not a reason to postpone the
          annual submission.`,
          icon: false,
        },
      ],
    },
    // 4
    {
      header: 'Are all corporations required to submit an annual filing?',
      body: [
        {
          content: `Yes. Every registered firm must comply with the RoC for "Private
          Limited Companies." The entity must adhere to the yearly abidance
          mandates, regardless of the annual turnover or capital amount. Every
          financial year, following the entity's AGM, the "annual compliance"
          is due.`,
          icon: false,
        },
      ],
    },
    {
      header:
        'Does the appointment of the statutory auditor fall under annual compliance?',
      body: [
        {
          content: `A company can appoint a statutory auditor either for five consecutive years or 
          till the conclusion of the next Annual general meeting. Therefore, an appointment of the 
          statutory auditor cannot be considered as a part of annual compliance.`,
          icon: false,
        },
      ],
    },
    {
      header: 'What are the compliances of a Private Limited Company?',
      body: [
        {
          content: `A company is required to maintain the compliances once the company is incorporated.
           The auditor is to be appointed within 30 days. Additionally, there is income tax filing and 
           annual return filing that is to be done every year.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Is it necessary to conduct AGM?',
      body: [
        {
          content: `The annual general meeting (AGM) is held for the management and the shareholders to 
          interact with each other. The Companies Act,2013 makes it compulsory to hold meetings to discuss
           the yearly results and appoint auditors.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Is it mandatory to get a Private Limited Company audited?',
      body: [
        {
          content: `The statutory audit as the name suggests is a mandatory audit for all companies. All 
          the entities that are unregistered under the Companies Act as Private or Public Limited Companies 
          need to get the books of accounts audited every year.`,
          icon: false,
        },
      ],
    },
    {
      header:
        ' Is audit report mandatory for all the private limited companies?',
      body: [
        {
          content: `The Private Limited Companies are required to file the annual accounts and the returns 
          that disclose the details of the shareholder and the directors to the ROC.`,
          icon: false,
        },
      ],
    },
  ];

  /* Tabs Data */
  const AnnualCompTabsData = {
    id: 'features',
    heading: 'Registration Procedure',
    paragraph: `The following are some of the essential contracts that a private
    limited corporation must have:`,
    body: [
      {
        tab_title: 'Meeting of Boards',
        tab_content: [
          {
            content_title: `Annual Requirements for a Private Limited Company`,
            content_paragraph: `The first meeting of the board must be held within a month
          or thirty days of incorporation and must include all of
          the company's directors. Each director of the corporation
          must receive formal notice of the declaration of the board
          meeting at least seven days prior to the meeting.`,
            points: [],
          },
          {
            content_title: 'Subsequent Board Meetings',
            content_paragraph: `Every year, the board must hold a minimum of four further
          meetings with a 120-day interval between them. Directors
          of the company must each submit a statement acknowledging
          any conflicts of interest at:`,
            points: [
              `The first board meeting he attends after being hired as a
            director.`,
              `The first gathering of the company's board of directors each
            fiscal year.`,
            ],
          },
        ],
        content_img: '/imgs/assited/cfo-management.png',
        alt_img: 'TODAYFILINGS-board meeting compliance',
      },
      {
        tab_title: 'Auditors',
        tab_content: [
          {
            content_title: 'First Auditor',
            content_paragraph: `Within 30 days of incorporation, the Board of Directors
          must appoint the company's first auditor, who will hold
          the position until the conclusion of the first annual
          general meeting. The filing of an ADT-1 is not required in
          the case of the First Auditor.`,
            points: [],
          },
          {
            content_title: 'Subsequent Auditor',
            content_paragraph: `In the first annual general meeting (AGM) of a private
          limited company, the BOD shall appoint the Auditor
          Auditor, who shall hold the post until the conclusion of
          the sixth AGM, and shall notify the ROC by submitting
          ADT-1. Within 15 days of the designation, Private Limited
          Company information, not Auditor Auditor information, must
          be submitted on Form ADT 1.`,
            points: [],
          },
          {
            content_title: 'Statutory Audit Of Accounts',
            content_paragraph: ` At the end of each financial year, every Private Limited
          Company must prepare its reports of accounts and acquire
          the correct audited by a Professionals/Chartered
          Accountant. To submit the firm to the Registrar, the
          Auditor must include an Audit Report and the company's
          audited financial statements.`,
            points: [],
          },
        ],
        content_img: '/imgs/assited/tax-audit-objective.png',
        alt_img: 'TODAYFILINGS-board of Auditor',
      },
      {
        tab_title: 'Filing Form',
        tab_content: [
          {
            content_title: 'Annual General Meeting',
            content_paragraph: `Every Private Limited Company is expected to schedule an
          Annual General Meeting within regular business hours on or
          before September 30th of each fiscal year. On a day that
          is not a general public holiday, or at the private limited
          company's registered office in the village, town, or city
          where the registered office is located. For the same, a
          notification of 21 bright days is required.`,
            points: [],
          },
          {
            content_title: 'Filing Of Annual Return (Form MGT-7)',
            content_paragraph: ` Within 60 days following the Annual General Meeting's
          operation, all Private Limited Companies are required to
          submit their Annual Returns. The company's annual return
          must be completed between "1st April and 31st March" of
          each year.`,
            points: [],
          },
          {
            content_title: 'Filing Of Financial Statements In (Form AOC-4)',
            content_paragraph: `Each private Limited Company must submit their "Balance
          Sheet" along with a statement or report of the "Director
          Report" and the "Profit and Loss Account" in this Form
          within 30 days of delaying the "Annual General Meeting."`,
            points: [],
          },
        ],
        content_img: '/imgs/business/registration.png',
        alt_img: 'TODAYFILINGS-Form Filing Of Annual Return',
      },
      {
        tab_title: 'Requirements',
        tab_content: [
          {
            content_title: 'Other Requirements of Annual Compliance',
            content_paragraph: `The Private Limited Company should file this, making sure
          to include all the pertinent information that a Small
          Company is obliged to provide under Section 134.`,
            points: [],
          },
          {
            content_title: `keeping up with the accounting records and statutory
          registers`,
            content_paragraph: `Statutory Registers, including the Directors and KMP, the
          Register of Members or Shareholders, the Register of
          Beneficial Owners, the Loans, Contracts and Arrangements
          or Deposits, the Loans, Contracts and Arrangements or
          Deposits, and so forth. Along with this, it is also
          necessary to maintain and keep up-to-date the ROC file,
          the books of accounts, the financial statements, the AGM
          or annual general meetings, and other meeting minutes.`,
            points: [],
          },
          {
            content_title: `The other documents and the annual financial statements`,
            content_paragraph: `The Private Limited Company should make sure that it has
          distributed or sent the annual financial statement, along
          with other papers like the Directors Report and the
          Auditors Report, to the members at the conclusion of the
          fiscal year, at least 21 clear days before having the AGM.`,
            points: [],
          },
        ],
        content_img: '/imgs/business/documents-one.png',
        alt_img: 'TODAYFILINGS-Annual Compliance Requirements',
      },
    ],
  };

  /* Document Section Data */
  const AnnualCompDocData = {
    id: 'ann-comp-doc',
    heading: `Checklist of Annual Compliance for Private Startups`,
    sub_heading: `Documents needed for Indian Subsidiary Company Registration`,
    doc_contents: [
      {
        col: `col-lg-4 col-md-4`,
        delay: '100',
        img: `/imgs/icons/bregistration/gst liabilities.png`,
        alt_tag: `GST Liability`,
        header: `GST Liability`,
        description: `Payment of recurring filings and payments: GST Liability,
        TDS & TCS Mandatory Payment.`,
      },
      {
        col: `col-lg-4 col-md-4`,
        delay: '200',
        img: `/imgs/icons/bregistration/annual-return.png`,
        alt_tag: `Annual Return`,
        header: `Annual Return`,
        description: `Periodic returns compliance by non-registrars - ( Annual
          returns, quarterly, monthly,- GST, TDS, etc..)`,
      },
      {
        col: `col-lg-4 col-md-4`,
        delay: '300',
        img: `/imgs/icons/bregistration/audit.png`,
        alt_tag: `Tax Audit Report`,
        header: `Tax Audit Report`,
        description: `Filing of the tax audit report by a certified and
        experienced accountant.`,
      },
      {
        col: `col-lg-4 col-md-4`,
        delay: '400',
        img: `/imgs/icons/bregistration/filingtax.png`,
        alt_tag: `Income Tax Returns`,
        header: `Income Tax Returns`,
        description: `Filing income tax returns with an additional 30% flat tax
        for education cess.`,
      },
      {
        col: `col-lg-4 col-md-4`,
        delay: '500',
        img: `/imgs/icons/bregistration/advancetax.png`,
        alt_tag: `Advance Tax`,
        header: `Advance Tax`,
        description: `Periodic assessment and payment of the advance tax payments.`,
      },
      {
        col: `col-lg-4 col-md-4`,
        delay: '600',
        img: `/imgs/icons/bregistration/compliant.png`,
        alt_tag: `Annual Compliance`,
        header: `Annual Compliance`,
        description: `Monthly/quarterly GST returns and quarterly TDS returns for
        your business.`,
      },
    ],
  };

  return (
    <div>
      <Seo
        title='Annual Compliance of a Private Limited Company'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />
      <Layout>
        <main id='main'>
          {/* <Hero sliders={AnnualCompSlider} /> */}
          <Package packageData={PackageCompData} />

          <About about_data={AnnualCompAboutData} />

          <Counter />

          <Service item={AnnualCompData} />

          <Document docSecData={AnnualCompDocData} />

          <GeneralTab HTabsData={AnnualCompTabsData} />
          <Cta />
          <FAQAccord items={AnnualCompFAQ} />
        </main>
      </Layout>
    </div>
  );
}
